<template>
  <div class="potential">
    <rxNavBar :title="navTitle"></rxNavBar>
    <p class="application_time">申请时间：<span>{{$route.query.reportingTime}}</span></p>
    <p class="subtitle">申请详情</p>
    <div class="panel">
        <div class="state">{{$route.query.approvalStatusStr}}</div>
      <van-row class="info">
        <van-col span="24">
          <p>申请人：<span>{{$route.query.staffName}}</span></p>
        </van-col>
        <van-col span="24">
          <p>端口有效期：<span>{{$route.query.termvalidity}}天</span></p>
        </van-col>
        <van-col span="12">
          <p>提报人数：<span>{{$route.query.Numberpeople}}人</span></p>
        </van-col>
        <van-col span="12">
          <p>提报总金额：<span>{{$route.query.portPrice}}元</span></p>
        </van-col>
        <van-col span="24">
          <p>端口周期：<span>{{$route.query.portBeginDate}} ~ {{$route.query.portEndDate}}</span></p>
        </van-col>
      </van-row>
    </div>
    <p class="subtitle">提报详情</p>
      <div class="panel">
          <div class="t_table">
            <van-row>
              <van-col span="1" class="t_header"></van-col>
              <van-col span="8" class="t_header">员工姓名</van-col>
              <van-col span="14" class="t_header">商品种类</van-col>
              <van-col span="1" class="t_header"></van-col>
            </van-row>

                <van-row v-for="item,index in staffDetail" :key="index" class="itemFontStyle">
                  <van-col span="1" class="t_td"></van-col>
                  <van-col span="8" class="t_td">{{item.staffName}}</van-col>
                  <van-col span="14" class="t_td">{{item.tradeName}}</van-col>
                  <van-col span="1" class="t_td"></van-col>
                </van-row>



          </div>
    </div>
    <div v-if="$route.query.approvalStatus" class="approve-opinion">
      <p class="subtitle">意见</p>
      <div class="panel">
      <van-row class="info">
        <van-col span="24">
          <p>审批意见：<span>{{staffDetail[0].approvalOpinion}}</span></p>
        </van-col>
        <van-col span="24" v-if="$route.query.approvalStatus==2">
          <p>驳回原因：<span>{{staffDetail[0].dictionaryTitle}}</span></p>
        </van-col>
      </van-row>
      </div>     
    </div>
    <div v-if="!auditOrCheck">
      <div class="panel radio_body withMargin">
        <p style="marginBottom:15px">
          <span :class="isChoose ? 'dot is_dot' : 'dot'"></span>
          审核结果
        </p>
        <div class="choose">
          <div :class="isPass == 1?'choose_btn pass': 'choose_btn'" @click="multipleChoice(1)">通过</div>
          <div class="line"></div>
          <div :class="isPass == 0?'choose_btn reject': 'choose_btn'" @click="multipleChoice(0)">驳回</div>
        </div>
      </div>
      <div id="cleanId" v-show="show" class="part-inputpart-row" @click="isCleanTypeShow = !isCleanTypeShow">
        <span :class="!selectCleanType.dictionaryTitle? 'notdot' : 'isdot'" > </span>
        <span class="part-inputpart-row-header">原因</span>
        <span class="divide">|</span>
        <span class="part-inputpart-row-graytext" :class="selectCleanType.dictionaryTitle ? 'part-inputpart-row-normaltext':'' ">{{selectCleanType.dictionaryTitle}}</span>
        <img class="part-inputpart-row-right" :class=" isCleanTypeShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' " src="../../../assets/images/triangle.png">
      </div>

      <div class="panel">
        <p class="yijian">审批意见</p>
        <div class="text_body">
          <van-field v-model="opinion"  type="textarea" class="mes_body" />
        </div>
      </div>
      <div class="sub_btn">
        <van-button  :class="!isChoose  ||( istype==0 && !selectCleanType.dictionaryTitle) ?'saveButton_Disable':'saveButton_Enable'" :disabled="!isChoose  || ( istype==0 && !selectCleanType.dictionaryTitle) " size="large" @click="save">保存
        </van-button>
      </div>
    </div>
    <van-popup v-model="isCleanTypeShow" position="bottom">
      <van-picker
          show-toolbar
          :columns="refuseReasonList"
          @cancel="isCleanTypeShow = false"
          :default-index="refuseReasonIndex"
          value-key="dictionaryTitle"
          @confirm="selectCleanTypeOption"
      />
    </van-popup>
  </div>
</template>
<script>
  import BScroll from 'better-scroll';
  import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
  import {
    NavBar,
    Tab,
    Tabs,
    Row,
    Col,
    DropdownMenu,
    DropdownItem,
    Tag,
    Button,
    Popup,
    Field,
    Divider,
    Image as VanImage,
    ImagePreview,
    Rate, DatetimePicker, Picker
  } from 'vant'
  import {
    decorationDetailInit,
    portRefuse,
    portAgree,
    portDetails
  } from "../../../getData/getData";
  import {getStaffId, responseUtil} from "../../../libs/rongxunUtil";
  export default {
    data() {
      return {
        refuseReasonIndex: '',
        //头部标题
        navTitle:'端口审批',
        // 服务人员评价显示隐藏
        istype:2,
        show:false,
        selectCleanType: '请选择',
        isCleanTypeShow: false,
        isCleanTypeSelect: false,
        refuseReasonList:[],
        isChoose: false,
        isPass: null,
        opinion: '',
        refuseId:'',
        auditOrCheck:false,
        staffDetail:[{approvalOpinion:'',dictionaryTitle:''}],     //提报详情
      }
    },
    name: 'ReviewOfDecorationScheme',
    components: {
      [NavBar.name]: NavBar,
      [Tab.name]: Tab,
      [Tabs.name]: Tabs,
      [Row.name]: Row,
      [Col.name]: Col,
      [DropdownMenu.name]: DropdownMenu,
      [DropdownItem.name]: DropdownItem,
      [Tag.name]: Tag,
      [Button.name]: Button,
      [Popup.name]: Popup,
      [Field.name]: Field,
      [Divider.name]: Divider,
      [VanImage.name]: VanImage,
      [ImagePreview.Component.name]: ImagePreview.Component,
      [DatetimePicker.name]: DatetimePicker,
      [Rate.name]: Rate,
      [Picker.name]: Picker,
      rxNavBar,
    },
    mounted() {
        if(this.$route.query.approvalStatus){
          this.auditOrCheck = true
          this.navTitle = '查看详情'
        }      
      this.getRefuseReasonList()
      this.getDetail()
    },
    methods: {
      // 拒绝原因
      selectCleanTypeOption(item,index) {
        this.selectCleanType = item
        this.isCleanTypeShow = false
        this.isCleanTypeSelect = true
        this.refuseId= this.refuseReasonList[index].id
      },
      multipleChoice(isPass) {
        if(isPass == 1){
          this.istype=1;
          this.show = false
        }else {
          this.istype=0;
          this.show = true
        }
        //审核结果
        this.isChoose = true;
        this.isCleanTypeSelect= false;
        //this.grade= 0;
        this.isPass = isPass
      },
      //保存
      save(){
        var that = this;
        var data = {}
        //通过的接口
        data.approvalOpinion=that.opinion
        data.id=that.$route.query.approvalId
        data.staff_id=getStaffId()
        if(that.istype == "1"){
            that.Agree(data)
        }
        //拒绝的接口
        if(that.istype == "0"){
          data.refuseReason_id=that.refuseId;
          that.refuse(data)
        }

      },
      Agree(data){
        let that=this
        portAgree(data).then(function (response) {
          responseUtil.dealResponse(that, response, () => {
            responseUtil.alertMsg(that,response.data.msg)
            that.leftReturn();
          })

        })
      },
      refuse(data){
        let that=this
        portRefuse(data).then(function (response) {
          responseUtil.dealResponse(that, response, () => {
            responseUtil.alertMsg(that,response.data.msg)
            that.leftReturn();
          })

        })
      },
      //提报详情
      getDetail(){
        let that = this;
        let initData = {};
        initData.id = that.$route.query.approvalId
        initData.addStaffId=getStaffId()
        portDetails(initData).then(function (response) {
          responseUtil.dealResponse(that, response, () => {
             that.staffDetail=response.data.data.data
          })
        })       

        //
      },
      getRefuseReasonList(){
        let that = this;
        let initData = {};
        initData.type = '19';
        initData.id = that.$route.query.approvalId
        decorationDetailInit(initData).then(function (response) {
          responseUtil.dealResponse(that, response, () => {
            that.refuseReasonList = response.data.data.refuseReasonList
          })
        })
      },      
      //返回上一层
      leftReturn(){
        this.$router.go(-1);
      },
    },
  }
</script>
<style lang="less" scoped>
  @redius: 8px;
  @border: 1px solid rgb(244, 244, 244);

  .part-inputpart {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    white-space: nowrap;
    overflow: hidden;
    font-size: 15px;
  }
  .part-inputpart-dropDownList{
    height: 300px;
    /*垂直方向滚动*/
    /*overflow-y: scroll;*/
    overflow: auto;
    width: 99.5%;
    position: absolute;
    z-index: 99;
    margin:4px 0 0 -19px;
  }
  .diamod {
    width: 8px;
    height: 8px;
    background-color: #3B98FF;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    margin: 17px 7px 0 18px;
    display: inline-block;
  }

  .t_title {
    background-color: white;
    color:#33ADFF;
    text-align: center;
    font-weight: 700;
    font-size: 12px;
    height: 20px;
  }
  .approve-opinion{
    margin-top:10px
  }
  .potential {
    width: 375px;
    background-color: rgb(250, 250, 250);
    padding: 0 15px;
    box-sizing: border-box;
    overflow: hidden;
    color: #252525;


    .sub_btn {
      margin: 30px 0 70px;

      .sub_button {
        border-radius: @redius;
        color: rgb(255, 255, 255);
        border: none;
        background: linear-gradient(rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
        background: -webkit-linear-gradient(left, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
        /* Safari 5.1 - 6.0 */
        background: -o-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
        /* Opera 11.1 - 12.0 */
        background: -moz-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
        /* Firefox 3.6 - 15 */
        background: linear-gradient(to right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));

        &.not_choose {
          background: none;
          background-color: rgba(184, 184, 184, 0.2);
        }
      }
    }

    p {
      margin: 0px;
    }

    .not_choose {
      background: none;
      background-color: rgba(184, 184, 184, 0.2);
    }

    .application_time {
      text-align: right;
      width: 345px;
      font-size: 12px;
      color: rgb(136, 136, 136);
      padding: 5px 0 5px;
    }

    .subtitle {
      padding: 5px 0px;
      color: rgb(244, 99, 76);
      margin-top: 15px;
      font-weight: 700;
      font-size: 12px;
    }
    .overDiv{
      height: 23px;
      /*line-height: 10px;*/
      /*border: 1px solid red;*/
      display: flex;
      align-items: center;
      /*justify-content: center;*/
    }
    /*实际结束更改*/
    .overDiv>div{
      font-weight: bolder;
    }
    .overDiv>span{
      font-size: 13px;
      color: rgb(110,110,110);
    }

    .calendar_icon {
      display: inline-block;
      width: 18px;
      height: 18px;
      background: url(../../../assets/images/calendar.png) no-repeat center center;
      background-size: cover;
      margin-left: 10px;
      background-position-y: -1px;
    }

    /*保存按钮不可点击样式*/
    .saveButton_Disable{
      /*background: linear-gradient(to right, rgba(184, 184, 184, 0.2), #B8B8B8 20%) repeat scroll 0% 0%;*/
      background-color: rgba(184, 184, 184, 0.2);
      color: white;
      width: 345px;
      height: 50px;
      border-radius: 8px;
      font-size: 18px;
      line-height: 1.22rem;
      margin-left: 0;
      text-align: center;
    }
    /*保存按钮可点击样式*/
    .saveButton_Enable{
      background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;
      color: white;
      width: 345px;
      height: 50px;
      border-radius: 8px;
      font-size: 18px;
      line-height: 1.22rem;
      text-align: center;
    }
    .dropDownList{
      width: 100%;
      position: absolute;
      z-index: 99;
      margin-top: 50px;
    }
    /*下拉菜单选项样式*/
    .part-inputpart-dropDownList-option{
      text-align: center;
      line-height: 35px;
      background-color: #f5f5f5;
      font-size: 12px;
      border-bottom: 1px white solid;
      color: black;
      float: left;
      width: 345px;
      height: 35px;
      /*position: fixed;*/
      /*z-index: 2001;*/
      /*margin-top: 300px;*/
      /*margin-left: 15px;*/
    }
    .divide {
      font-size: 14px;
      color: #efefef;
      margin:0 7px 0 10px;
    }
    .part-inputpart-row-header{
      font-weight: bold;
      font-size: 14px;
      width: 90px;
      color: black;
    }
    .part-inputpart-row-graytext {
      color: #d9d9d9;
      width: 100%;
      font-size: 14px;
    }
    .part-inputpart-row {
      width: 345px;
      float: left;
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: white;
      height: 50px;
      border-radius: 8px;
      margin: 0 5px 15px 0;
    }
    .notdot {
      display: inline-block;
      padding: 3.5px;
      border-radius: 50%;
      background-color: rgb(136, 136, 136);
      vertical-align: middle;
      margin-right: 13px;
      margin-left: 15px;
    }
    .isdot{
      display: inline-block;
      width: 8px;
      height: 6px;
      border-radius: 50%;
      vertical-align: middle;
      margin-right: 13px;
      background-color: rgb(251,91,64);
      margin-left: 15px;
    }
    .optionSelectedColor{
      color: #ff5d3b;
    }
    .part-inputpart-dropDownList-option-selected{
      color: #ff5d3b;
    }
    .part-inputpart-row-normaltext{
      color: black;
    }
    .part-inputpart-row-right-downArrow{
      width: 9px;
      height: 9px;
      margin-right: 15px;
    }
    /*选择三角图标的向上向下样式*/
    .part-inputpart-row-right-upArrow{
      width: 9px;
      height: 9px;
      /*transform: rotateX(180deg);*/
      margin-right: 15px;
    }

    .panel {
      width: 345px;
      background-color: rgb(255, 255, 255);
      border-radius: @redius;
      position: relative;
      font-size: 14px;
      text-align: left;
      overflow: hidden;


      &.withMargin {
        margin: 16px 0;
      }

      &>p {
        padding: 15px 15px 0px;
        font-weight: bolder;
        /*color: #252525;*/

      }

      &>p>span {
        font-weight: normal;
        color: rgb(190, 190, 190);
      }

      .state {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 20px;
        font-weight: bold;
        height: 32px;
        line-height: 32px;
        width: 60px;
        display: block;
        background: linear-gradient(rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
        background: -webkit-linear-gradient(left, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
        /* Safari 5.1 - 6.0 */
        background: -o-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
        /* Opera 11.1 - 12.0 */
        background: -moz-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
        /* Firefox 3.6 - 15 */
        background: linear-gradient(to right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
        border-top-left-radius: 16px;
        border-bottom-right-radius: 16px;
        color: rgb(255, 255, 255);
        text-align: center;
        transform: scale(0.5);
        transform-origin: 0% 0%;
        font-family: sans-serif;
        padding: 0 10px;
      }

      .title {
        width: 315px;
        padding: 0 15px;
        font-weight: bolder;
        color: rgb(0, 0, 0);
        font-size: 16px;
        padding-top: 20px;

        span {
          font-size: 12px;
          font-weight: normal;
          color: rgba(153, 153, 153, 1);
        }
      }
      .allprice{
        padding-bottom: 4px;
      }
      /*审核结果,服务人员 审批意见*/
      .shenhe,.fuwu,.yijian{
        font-size: 15px;
      }
      .describe {
        padding-bottom: 15px;
        padding-top: 10px;
        color: rgb(82, 82, 82);
        font-weight: normal;
      }

      .info {
        padding: 15px;
        line-height: 16px;

        &.noPadding {
          padding: 0 15px;
        }

        p {
          margin: 7px 0;
          font-weight: bolder;

          span {
            font-size: 13px;
            font-weight: normal;
            color: rgb(110, 110, 110);
          }
        }

        .floatR {
          float: right;
        }

        .total_color {
          color: rgba(255, 93, 59, 1);
        }
      }

      .text_body {
        padding: 15px;

        .mes_body {
          border-radius: @redius;
          background-color: rgb(250, 250, 250);
        }
      }
    }

    // 单选框
    .radio_body {
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
      align-items: center;

      .dot {
        display: inline-block;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: rgb(136, 136, 136);
        vertical-align: middle;
        margin-right: 10px;
        margin-bottom: 6px;

        &.is_dot {
          background-color: rgb(251, 91, 64);
        }
      }

      .line {
        width: 2px;
        height: 12px;
        background-color: rgb(250, 250, 250);
      }

      .choose {
        margin-right: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 15px;

        .choose_btn {
          padding: 5px;
          margin: 10px;
          color: rgb(136, 136, 136);
          cursor: pointer;

          &.pass {
            color: rgb(251, 91, 64);
            font-weight: bolder;
          }

          &.reject {
            color: red;
            font-weight: bolder;
          }
        }
      }
    }

    // 循环表单
    .t_table {
      .t_header {
        text-align: left;
        color: rgb(136, 136, 136);
        /*font-weight: bolder;*/
        font-size: 10px;
        height: 20px;
        line-height: 21px;
        background-color: rgba(243, 249, 255, 1);
      }

      .t_title {
        //background-color: rgb(244, 249, 255);
        color:#33ADFF;
        text-align: center;
        font-weight: 700;
        font-size: 12px;
        height: 20px;
      }

      .t_td {
        text-align: left;
        height: 40px;
        line-height: 40px;
        font-size: 13px;
        white-space: nowrap;
        overflow: hidden;
      }
      .t_td_1 {
        text-align: left;
        height: 40px;
        line-height: 40px;
        font-size: 13px;
        padding-left: 10px;
      }
    }

    // 图片墙
    .swiper_show {
      width: 325px;
      position: relative;
      margin: 15px 10px;
      height: 75px;

      .content {
        position: absolute;
        display: flex;
        justify-content: space-around;
        left: 0px;

        .imgWrap {
          margin-right: 10px;
        }
      }

      .num {
        position: absolute;
        left: 4px;
        top: 6px;
        font-size: 10px;
        background-color: rgb(0, 0, 0);
        opacity: .5;
        color: rgb(255, 255, 255);
        padding: 2px 6px;
        display: inline-block;
        border-radius: 10px;
        font-weight: bolder;
        line-height: 10px;
      }
    }
  }
</style>
